// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Clients Section */
.clients-section {
    background-color: #f8f8f8;
    text-align: center;
  }
  
  .clients-title {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  /* Clients Container */
  .clients-container {
    overflow: hidden;
    width: 100%;
    padding: 20px;
  }
  
  .clients-slider {
    display: flex;
    width: 200%; /* To accommodate both sets of logos (original + duplicate) */
    animation: slideClients 12s linear infinite; /* Ensure the animation is infinite */
  }
  
  .client-logo-card {
    width: 400px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
  }
  
  .client-logo-img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  
  /* Infinite Loop Animation */
  @keyframes slideClients {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Move by 50% of the total width */
    }
  }
  
@media (max-width:480px) {
  .client-logo-img {
    width: 100px;
    height: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Clients.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA,sBAAsB;EACtB;IACE,gBAAgB;IAChB,WAAW;IACX,aAAa;EACf;;EAEA;IACE,aAAa;IACb,WAAW,EAAE,6DAA6D;IAC1E,2CAA2C,EAAE,qCAAqC;EACpF;;EAEA;IACE,YAAY;IACZ,cAAc;IACd,aAAa;IACb,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB;EACrB;;EAEA,4BAA4B;EAC5B;IACE;MACE,wBAAwB;IAC1B;IACA;MACE,2BAA2B,EAAE,mCAAmC;IAClE;EACF;;AAEF;EACE;IACE,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":["/* Clients Section */\r\n.clients-section {\r\n    background-color: #f8f8f8;\r\n    text-align: center;\r\n  }\r\n  \r\n  .clients-title {\r\n    font-size: 2.5em;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  /* Clients Container */\r\n  .clients-container {\r\n    overflow: hidden;\r\n    width: 100%;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .clients-slider {\r\n    display: flex;\r\n    width: 200%; /* To accommodate both sets of logos (original + duplicate) */\r\n    animation: slideClients 12s linear infinite; /* Ensure the animation is infinite */\r\n  }\r\n  \r\n  .client-logo-card {\r\n    width: 400px;\r\n    margin: 0 20px;\r\n    display: flex;\r\n    justify-content: center;\r\n  }\r\n  \r\n  .client-logo-img {\r\n    width: 100%;\r\n    height: auto;\r\n    object-fit: contain;\r\n  }\r\n  \r\n  /* Infinite Loop Animation */\r\n  @keyframes slideClients {\r\n    0% {\r\n      transform: translateX(0);\r\n    }\r\n    100% {\r\n      transform: translateX(-50%); /* Move by 50% of the total width */\r\n    }\r\n  }\r\n  \r\n@media (max-width:480px) {\r\n  .client-logo-img {\r\n    width: 100px;\r\n    height: 100px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
