import React, { useState } from 'react';
import './EnquiryForm.css';  // Make sure this CSS file is properly linked
const baseurl = `https://backend.aeriestec.com/`;

const EnquiryForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    if (!formData.name || !formData.email || !formData.mobile || !formData.message) {
      setError('Please fill all fields.');
      return;
    }

    try {
      const response = await fetch(`${baseurl}/api/enquiries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccess('Enquiry submitted successfully!');
        setFormData({
          name: '',
          email: '',
          mobile: '',
          message: '',
        });
        setError('');
      } else {
        setError('Failed to submit enquiry.');
      }
    } catch (error) {
      setError('Error submitting enquiry.');
    }
  };

  return (
    <div className="enquiry-container">
      <div className="form-container">
        {/* Form Section */}
        <div className="form-section">
          <h2 className='enquiry-card-heading'>Enquire Now</h2>
          <form onSubmit={handleSubmit}>
            {error && <p className="error-message">{error}</p>}
            {success && <p className="success-message">{success}</p>}

            <input
              type="text"
              name="name"
              className='enquery-form-input'
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <input
              type="email"
              name="email"
              className='enquery-form-input'
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <input
              type="tel"
              name="mobile"
              className='enquery-form-input'
              placeholder="Your Mobile Number"
              value={formData.mobile}
              onChange={handleChange}
              required
            />
            <textarea
              name="message"
              placeholder="Your Message"
              className='enquery-form-input'
              value={formData.message}
              onChange={handleChange}
              required
            ></textarea>
            <button className="enquire-button" type="submit">
              Enquire
            </button>
          </form>
        </div>

        {/* Why Invest with Us Section */}
        <div className="why-invest-section">
          <h2 className='enquiry-card-heading'>Why Invest with Us?</h2>
          <ul className="features-list">
            <li>Proven track record of successful investments</li>
            <li>Expert team with industry insights</li>
            <li>Guaranteed returns on investment</li>
            <li>Tailored investment strategies for each client</li>
            <li>Transparent and customer-first approach</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EnquiryForm;
