// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-us {
    text-align: center;
    font-family: "Times New Roman", Times, serif;
    padding: 20px;
    /*background-image: linear-gradient(
    to right,
    green,rgb(11, 177, 19)
  ) ;*/
  background-image: linear-gradient(
    to right, black,green
  ) ;
    border-radius: 8px;
    margin: 50px auto;
    width: 100%; /* Adjust width as needed */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    color:white;
}

.contact-us-bg-image {
    background-size: cover;
    width: 600px;

}

.contact-us-form-container {
    width: 100%;
    margin-left: 20px;
}

.contact-us-heading {
    font-size: 2rem;
    margin-bottom: 20px;
}

.contact-us-description {
    font-size: 1.2rem;
    margin: 10px 0;
}

@media (max-width:1024px) {
    .contact-us-bg-image {
        width: 380px;
    }
}

@media (max-width:480px) {
    .contact-us {
        flex-direction: column;
        margin: 0px;
    }
    .contact-us-bg-image {
        width: 100%;
        margin: 0px;
    }
    .contact-us-form-container {
        padding: 30px;
        margin: 0px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ContactUs.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4CAA4C;IAC5C,aAAa;IACb;;;MAGE;EACJ;;IAEE;IACA,kBAAkB;IAClB,iBAAiB;IACjB,WAAW,EAAE,2BAA2B;IACxC,yCAAyC;IACzC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,YAAY;;AAEhB;;AAEA;IACI,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,YAAY;IAChB;AACJ;;AAEA;IACI;QACI,sBAAsB;QACtB,WAAW;IACf;IACA;QACI,WAAW;QACX,WAAW;IACf;IACA;QACI,aAAa;QACb,WAAW;IACf;AACJ","sourcesContent":[".contact-us {\r\n    text-align: center;\r\n    font-family: \"Times New Roman\", Times, serif;\r\n    padding: 20px;\r\n    /*background-image: linear-gradient(\r\n    to right,\r\n    green,rgb(11, 177, 19)\r\n  ) ;*/\r\n  background-image: linear-gradient(\r\n    to right, black,green\r\n  ) ;\r\n    border-radius: 8px;\r\n    margin: 50px auto;\r\n    width: 100%; /* Adjust width as needed */\r\n    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    color:white;\r\n}\r\n\r\n.contact-us-bg-image {\r\n    background-size: cover;\r\n    width: 600px;\r\n\r\n}\r\n\r\n.contact-us-form-container {\r\n    width: 100%;\r\n    margin-left: 20px;\r\n}\r\n\r\n.contact-us-heading {\r\n    font-size: 2rem;\r\n    margin-bottom: 20px;\r\n}\r\n\r\n.contact-us-description {\r\n    font-size: 1.2rem;\r\n    margin: 10px 0;\r\n}\r\n\r\n@media (max-width:1024px) {\r\n    .contact-us-bg-image {\r\n        width: 380px;\r\n    }\r\n}\r\n\r\n@media (max-width:480px) {\r\n    .contact-us {\r\n        flex-direction: column;\r\n        margin: 0px;\r\n    }\r\n    .contact-us-bg-image {\r\n        width: 100%;\r\n        margin: 0px;\r\n    }\r\n    .contact-us-form-container {\r\n        padding: 30px;\r\n        margin: 0px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
