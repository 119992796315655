import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home-main-container">
      <div className="content-container">
        <h1 className="home-heading">
          Secure Your Financial Future with Smarter Investments
        </h1>
        <h2 className="home-sub-heading">
         JOIN US TODAY for secured investments and get 120% - 150% Returns.
        </h2>
        <a href="#enquiry-form">
          <button className="home-button">Know more...</button>
        </a>
      </div>
    </div>
  );
};

export default Home;
