import React, { useState, useEffect } from "react";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import PricingPlans from "./components/PricingPlans";
import EnquiryForm from "./components/EnquiryForm";
import Testimonials from "./components/Testimonials";
import Clients from "./components/Clients";
import ContactUs from "./components/ContactUs";
import Home from "./components/Home";
import "./styles.css";
import "./App.css";

import 'bootstrap/dist/css/bootstrap.min.css';
import InsertFile from "./components/InsertFile";

function App() {
  const [showTopButton, setShowTopButton] = useState(false);

  // Show or hide the scroll-to-top button
  useEffect(() => {
    const handleScroll = () => {
      setShowTopButton(window.scrollY > 300);
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to the specified section
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="App">
      {/* Header */}
      <header className="header">
        <div className="image-container">
          <img
            src="https://res.cloudinary.com/dkujcnlcs/image/upload/v1737689344/admmm_fztklf.png"
            className="nav-img1"
          />
          <h1 className="main-logo-heading">ADM Ventures</h1>
        </div>
        <nav>
          <ul className="nav-links">
            <li>
              <button
                onClick={() => scrollToSection("about-us")}
                className="header-button"
              >
                About Us
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("services")}
                className="header-button"
              >
                Services
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("pricing-plans")}
                className="header-button"
              >
                Pricing Plans
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("enquiry-form")}
                className="header-button"
              >
                Enquiry
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("testimonials")}
                className="header-button"
              >
                Testimonials
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("clients")}
                className="header-button"
              >
                Clients
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection("contact-us")}
                className="header-button"
              >
                Contact Us
              </button>
            </li>
            
          </ul>
        </nav>
      </header>

      {/* Sections */}
      <main>
        <section>
          <Home />
        </section>
        <section id="about-us">
          <AboutUs />
        </section>
        <div id="services">
          <Services />
        </div>
        <div id="pricing-plans">
          <PricingPlans />
        </div>
        <div id="enquiry-form">
          <EnquiryForm />
        </div>
        <div id="testimonials">
          <Testimonials />
        </div>
        <div id="clients">
          <Clients />
        </div>
        <div id="contact-us">
          <ContactUs />
        </div>
        {/* <div>
          <InsertFile />
        </div> */}
      </main>

      {/* Scroll-to-Top Button */}
      {showTopButton && (
        <button className="scroll-to-top" onClick={scrollToTop}>
          ↑
        </button>
      )}

      {/* Footer */}
      <footer className="footer">
        <p>© 2024 Investment Solutions. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
