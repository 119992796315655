import React from "react";
import "./Testimonials.css";

const testimonialsData = [
  {
    text: "ADM Ventures has been instrumental in diversifying my investment portfolio with their strategic insights and innovative approach. Their team is highly knowledgeable and responsive, making the entire process seamless and rewarding. I highly recommend their services to anyone seeking solid investment solutions.",
    client: "Neha Sharma"
  },
  {
    text: "Partnering with ADM Ventures has transformed my investment strategy. Their expert team offers personalized insights that align perfectly with my financial goals, ensuring growth and security. The professionalism and dedication they demonstrate is truly commendable.",
    client: "Rohit Kapoor"
  },
  {
    text: "ADM Ventures has been pivotal in guiding my investment decisions with their expert advice and tailored strategies. Their commitment to client success is unmatched, providing clarity and confidence in every step. I'm grateful for their unwavering support and highly recommend their services.",
    client: "Amit Verma"
  }
];

const Testimonials = () => (
  <section className="testimonials-section">
    <h2 className="testimonials-title">What Our Investors Say</h2>
    <div className="testimonials-container">
      <div className="testimonials-slider">
        {testimonialsData.map((testimonial, index) => (
          <div className="testimonial-card" key={index}>
            <p className="testimonial-text">"{testimonial.text}"</p>
            <p className="testimonial-client"><strong>- {testimonial.client}</strong></p>
          </div>
        ))}
        {testimonialsData.map((testimonial, index) => (
          <div className="testimonial-card" key={`duplicate-${index}`}>
            <p className="testimonial-text">"{testimonial.text}"</p>
            <p className="testimonial-client"><strong>- {testimonial.client}</strong></p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Testimonials;
