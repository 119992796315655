import React from "react";
import { motion } from "framer-motion";
import { FaBriefcase, FaCreditCard, FaChartLine } from "react-icons/fa";
import { GiPayMoney } from "react-icons/gi";

import "./Services.css";

const Services = () => (
  <div className="services-main-container">
    <motion.section
      id="services"
      initial={{ x: "-100vw" }}
      animate={{ x: 0 }}
      transition={{ type: "spring", stiffness: 50 }}
    >
      <div class="container">
        <h2 className="services-heading">Our Services</h2>
      </div>
      <div className="services-cards-container">
        <div className="services-card-container">
          <GiPayMoney className="services-icons" />
          <div className="services-card-sub-container">
            <h1 className="services-card-heading">Investing Plans</h1>
            <p className="service-card-description">Versatile plans as per your pocket available in Basic, Advance, Exclusive mode to invest monthly</p>
          </div>
        </div>
        <div className="services-card-container">
          <FaCreditCard className="services-icons" />
          <div className="services-card-sub-container">
            <h1 className="services-card-heading">Immediate and easy widthdrawls</h1>
            <p className="service-card-description">You can start withdrawing easily as early as 30 days from investment</p>
          </div>
        </div>
        <div className="services-card-container">
          <FaChartLine className="services-icons" />
          <div className="services-card-sub-container">
            <h1 className="services-card-heading">Financial growth tracking</h1>
            <p className="service-card-description">Get a tailored tracking page to track your investments and returns</p>
          </div>
        </div>
      </div>

      {/* <h2 className="services-heading">Our Services</h2> */}
      {/* <p className="services-paragraph">
        We offer personalized investment plans, portfolio management, and expert
        advice.
      </p> */}
    </motion.section>
  </div>
);

export default Services;
