import React, { useState } from "react";

const InsertFile = () => {
  const [topic, setTopic] = useState("");
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!topic) {
      setMessage("Topic is required.");
      return;
    }

    const formData = new FormData();
    formData.append("topic", topic);
    if (file) {
      formData.append("uploadsImg", file);
    }

    try {
      const response = await fetch("http://localhost:3005/enter/topics", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setMessage(result.message || "Uploaded successfully!");
      } else {
        const error = await response.text();
        console.error("Error response:", error);
        setMessage("Failed to upload. Please try again.");
      }
    } catch (error) {
      console.error("Fetch error:", error);
      setMessage("Failed to upload. Please check your connection.");
    }
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <h1>Upload Topic</h1>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "10px" }}>
          <label>
            Topic:
            <input
              type="text"
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              style={{ marginLeft: "10px", padding: "5px" }}
              required
            />
          </label>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <label>
            Upload Image:
            <input
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              style={{ marginLeft: "10px" }}
            />
          </label>
        </div>
        <button type="submit" style={{ padding: "10px 20px" }}>
          Submit
        </button>
      </form>
      {message && (
        <div
          style={{
            marginTop: "20px",
            padding: "10px",
            color: message.includes("Failed") ? "red" : "green",
          }}
        >
          {message}
        </div>
      )}
    </div>
  );
};

export default InsertFile;
