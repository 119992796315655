import React from 'react';
import './Clients.css';

const clientsData = [
  {
    logo: "https://res.cloudinary.com/dabzdwxet/image/upload/v1733729176/invest-client-logo-1_fpcdoa.jpg",
    name: "Client 1"
  },
  {
    logo: "https://res.cloudinary.com/dabzdwxet/image/upload/v1733729176/invest-client-logo-3_ec5zbl.jpg",
    name: "Client 2"
  },
  {
    logo: "https://res.cloudinary.com/dabzdwxet/image/upload/v1733729176/invest-client-logo-5_faihgz.jpg",
    name: "Client 3"
  },
  {
    logo: "https://res.cloudinary.com/dabzdwxet/image/upload/v1733729176/invest-client-logo-2_fprijl.jpg",
    name: "Client 4"
  },
  {
    logo: "https://res.cloudinary.com/dabzdwxet/image/upload/v1733729176/invest-client-logo-4_bu1ar3.jpg",
    name: "Client 5"
  },
  {
    logo: "https://res.cloudinary.com/dabzdwxet/image/upload/v1733729176/invest-client-logo-6_micp8i.jpg",
    name: "Client 6"
  }
];

const Clients = () => (
  <section className="clients-section">
    <h2 className="clients-title">Our Clients</h2>
    <div className="clients-container">
      <div className="clients-slider">
        {clientsData.map((client, index) => (
          <div className="client-logo-card" key={index}>
            <img
              className="client-logo-img"
              src={client.logo}
              alt={client.name}
            />
          </div>
        ))}
        {/* Duplicate the logos to make the slider loop */}
        {clientsData.map((client, index) => (
          <div className="client-logo-card" key={`duplicate-${index}`}>
            <img
              className="client-logo-img"
              src={client.logo}
              alt={client.name}
            />
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Clients;
