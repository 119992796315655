import React from "react";
import "./ContactUs.css"; 
 
const ContactUs = () => (
  <section id="contact-us" className="contact-us">
    <div className="contact-us-bg-image-container">
        <img className="contact-us-bg-image" src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733830860/invest-plan-contact-us-bg_qurb5w.jpg" alt="contact-us-img" />
    </div>
    <div className="contact-us-form-container">
      <h2>Contact Us</h2>
      <p>Email: admventures88@gmail.com</p>
      <p>Phone: 6301566089</p>
      <p>
        Address: Quadrant -1, A3, Cyber Towers, Floor - 10, Hi-tech City,
        Madhapur - 500081
      </p>
    </div>
  </section>
);
 
export default ContactUs;
 