import React from "react";
import { Carousel } from "react-bootstrap";
import "./AboutUs.css"; // Assuming you'll style the cards and animations in a separate CSS file.

const AboutUs = () => {
  return (
    <div className="about-us-container">
      {/* Main Content Card */}
      <div className="content-card">
        <div className="about-us-ourstory">
          <Carousel className="about-us-img-container" fade interval={3000}>
            {/* Slide 1 */}
            <Carousel.Item>
              <img
                src="https://res.cloudinary.com/ddjsaoac6/image/upload/v1733405787/investment-growth_lnaioz.webp"
                alt="Investment Solutions"
                className="about-us-image"
              />
            </Carousel.Item>
            {/* Slide 2 */}
            <Carousel.Item>
              <img
                src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733815140/About-us-4_racb1a.jpg"
                alt="Investment Growth 2"
                className="about-us-image"
              />
            </Carousel.Item>
            {/* Slide 3 */}
            <Carousel.Item>
              <img
                src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733815140/investment_solutions_good_returns_for_happy_family_hn4tb1.jpg"
                alt="Investment Growth 3"
                className="about-us-image"
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                src="https://res.cloudinary.com/dabzdwxet/image/upload/v1733815139/investment-solution-about-us-2_cxpjij.png"
                alt="Investment Growth 3"
                className="about-us-image"
              />
            </Carousel.Item>
          </Carousel>
        </div>
        <div className="about-us-ourstory about-us-margin">
          <h2 className="about-us-heading">About Us</h2>
          <ul className="about-us-description">
            <li className="about-us-description-list-item"><strong>Commitment to Financial Freedom</strong>: At ADM Investment Solutions, we strive to make financial freedom accessible to everyone through secure and profitable investment opportunities.</li>
            <li className="about-us-description-list-item"><strong>Tailored Investment Solutions</strong>: We offer a range of personalized investment solutions to meet the unique needs of individuals at different stages of their financial journey.</li>
            <li className="about-us-description-list-item"><strong>Expert Guidance and Innovation</strong>: Our expert team is dedicated to providing transparent, reliable, and innovative options, empowering you to take control of your financial future and achieve success.</li>
          </ul>
        </div>
      </div>

      {/* Mission and Vision Cards Section */}
      <div className="cards-container">
        {/* Mission Card */}
        <div className="card mission-card">
          <h3>Mission</h3>
          <p>
            To provide secure, innovative, and accessible investment solutions
            that empower individuals to achieve their financial goals and
            realize their dreams.
          </p>
        </div>

        {/* Vision Card */}
        <div className="card vision-card">
          <h3>Vision</h3>
          <p>
            To be a global leader in investment solutions, guiding individuals
            toward financial independence and lasting prosperity.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
